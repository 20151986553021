// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../styleguide/components/Heading/H1.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as React from "react";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as MarketplaceIndexScss from "./MarketplaceIndex.scss";
import * as MarketplaceIndexFilters from "./MarketplaceIndexFilters.res.js";

var css = MarketplaceIndexScss;

function MarketplaceIndexMobileFilters(props) {
  var apply = props.apply;
  var filters = props.filters;
  var initialState = React.useMemo((function () {
          return {
                  shown: false,
                  input: filters
                };
        }), []);
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action === "object") {
            return {
                    TAG: "Update",
                    _0: {
                      shown: state.shown,
                      input: action._0
                    }
                  };
          }
          switch (action) {
            case "ShowFilters" :
                return {
                        TAG: "Update",
                        _0: {
                          shown: true,
                          input: filters
                        }
                      };
            case "Apply" :
                return {
                        TAG: "SideEffects",
                        _0: (function (param) {
                            apply(param.state.input);
                            param.dispatch("Hide");
                          })
                      };
            case "Hide" :
                return {
                        TAG: "Update",
                        _0: {
                          shown: false,
                          input: state.input
                        }
                      };
            case "Cancel" :
                return {
                        TAG: "Update",
                        _0: {
                          shown: false,
                          input: filters
                        }
                      };
            
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H1.make, {
                              className: css.mobileHeaderTitle,
                              children: "Marketplace"
                            }),
                        state.shown ? null : JsxRuntime.jsx(Button.make, {
                                size: "XS",
                                color: "Teal",
                                onClick: (function (param) {
                                    dispatch("ShowFilters");
                                  }),
                                children: "Filters"
                              })
                      ],
                      className: css.mobileHeader
                    }),
                state.shown ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                        children: [
                          JsxRuntime.jsx(MarketplaceIndexFilters.make, {
                                filters: state.input,
                                providers: props.providers,
                                providersGrouped: props.providersGrouped,
                                totalProviders: props.totalProviders,
                                categoryFilters: props.categoryFilters,
                                totalLocations: props.totalLocations,
                                className: css.mobileFilters,
                                update: (function (input) {
                                    dispatch({
                                          TAG: "UpdateInput",
                                          _0: input
                                        });
                                  })
                              }),
                          JsxRuntime.jsxs("div", {
                                children: [
                                  JsxRuntime.jsx(Button.make, {
                                        size: "SM",
                                        color: "Gray",
                                        expanded: true,
                                        className: css.mobileFiltersActionBarButton,
                                        onClick: (function (param) {
                                            dispatch("Cancel");
                                          }),
                                        children: "Cancel"
                                      }),
                                  JsxRuntime.jsx(Button.make, {
                                        size: "SM",
                                        color: "Teal",
                                        expanded: true,
                                        className: css.mobileFiltersActionBarButton,
                                        onClick: (function (param) {
                                            dispatch("Apply");
                                          }),
                                        children: "Apply"
                                      })
                                ],
                                className: css.mobileFiltersActionBar
                              })
                        ]
                      }) : null
              ]
            });
}

var make = MarketplaceIndexMobileFilters;

export {
  css ,
  make ,
}
/* css Not a pure module */
