// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Control from "../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../styleguide/components/Popover/Popover.res.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as MarketplaceFilterScss from "./MarketplaceFilter.scss";

var css = MarketplaceFilterScss;

function renderOption(provider, filters, update) {
  var providerId = ID.toString(provider.id);
  var optionId = "marketplace-filter-provider-" + providerId;
  return JsxRuntime.jsx(Checkbox.WithLabel.make, {
              id: optionId,
              size: "MD",
              checked: (function (__x) {
                    return Js_array.findIndex((function (value) {
                                  return ID.toString(value) === providerId;
                                }), __x);
                  })(filters.providerIds) > -1,
              className: css.filterOption,
              containerClassName: css.containerCheckbox,
              onChange: (function ($$event) {
                  var checked = $$event.target.checked;
                  var selectedValues = filters.providerIds;
                  if (checked) {
                    return update({
                                locations: filters.locations,
                                providerIds: Belt_Array.concat(selectedValues, [provider.id]),
                                totalPowerKw: filters.totalPowerKw,
                                powerCircuits: filters.powerCircuits,
                                subcategories: filters.subcategories,
                                category: filters.category
                              });
                  } else {
                    return update({
                                locations: filters.locations,
                                providerIds: Belt_Array.keep(selectedValues, (function (value) {
                                        return Caml_obj.notequal(value, provider.id);
                                      })),
                                totalPowerKw: filters.totalPowerKw,
                                powerCircuits: filters.powerCircuits,
                                subcategories: filters.subcategories,
                                category: filters.category
                              });
                  }
                }),
              children: provider.name
            }, optionId);
}

var Helpers = {
  renderOption: renderOption
};

function MarketplaceProviderFilter(props) {
  var totalProviders = props.totalProviders;
  var update = props.update;
  var filters = props.filters;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: "Provider",
                      className: css.filterTitle
                    }),
                Belt_Array.map(props.providers, (function (provider) {
                        return renderOption(provider, filters, update);
                      })),
                totalProviders > 5 ? JsxRuntime.jsxs(Popover.make, {
                        children: [
                          JsxRuntime.jsx(Popover.Trigger.make, {
                                children: JsxRuntime.jsx(Control.AsLink.make, {
                                      inline: true,
                                      className: css.showAllLink,
                                      onClick: (function (param) {
                                          
                                        }),
                                      children: "All " + (String(totalProviders) + " providers")
                                    })
                              }),
                          JsxRuntime.jsx(Popover.Body.make, {
                                position: "OnRight",
                                className: css.popover,
                                children: JsxRuntime.jsx("div", {
                                      children: Belt_Array.map(props.allProviders, (function (providersByLetter) {
                                              return JsxRuntime.jsxs("div", {
                                                          children: [
                                                            JsxRuntime.jsx("div", {
                                                                  children: providersByLetter.letter,
                                                                  className: css.letter
                                                                }),
                                                            JsxRuntime.jsx("div", {
                                                                  children: Belt_Array.map(providersByLetter.providers, (function (provider) {
                                                                          return renderOption(provider, filters, update);
                                                                        })),
                                                                  className: css.group
                                                                })
                                                          ],
                                                          className: css.letterGroup
                                                        }, "marketplace-filter-provider-letter-" + providersByLetter.letter);
                                            })),
                                      className: css.providersPopoverContainer
                                    })
                              })
                        ]
                      }) : null
              ],
              className: css.filterContainer
            });
}

var maxItems = 5;

var make = MarketplaceProviderFilter;

export {
  css ,
  maxItems ,
  Helpers ,
  make ,
}
/* css Not a pure module */
