/* eslint-disable import/no-extraneous-dependencies */
import 'react-notifications/lib/notifications.css';
/* eslint-enable import/no-extraneous-dependencies */

import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import ReactOnRails from 'react-on-rails';
import LoginMiddleware from './LoginMiddleware';

let loginMiddlewareCounter = 0;

export default class ReduxProviderClient extends React.PureComponent {
  render() {
    const AppStore = ReactOnRails.getStore('AppStore');
    loginMiddlewareCounter += 1;
    return (
      <Provider store={AppStore}>
        <Fragment>
          {loginMiddlewareCounter < 2 && (
            <Fragment>
              <LoginMiddleware />
            </Fragment>
          )}
          {this.props.children}
        </Fragment>
      </Provider>
    );
  }
}
