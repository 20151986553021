import { Component } from 'react';
import ApiUtils from 'Utils/apiUtil';
import { connect } from 'react-redux';
import { setUserData as setUserDataAction } from 'Bundles/Client/common/actions/userActions';
import _ from 'lodash';

class LoginMiddleWare extends Component {
  // eslint-disable-next-line camelcase
  componentDidMount = () => {
    const { userData, setUserData } = this.props;
    if (!_.isEmpty(userData) || userData.length > 0) {
      ApiUtils.getIsUserSignedIn().then(response => {
        if (response.data.user_log_in === false) {
          setUserData([]);
          localStorage.clear();
          window.location = '/users/sign_in';
        }
      });
    }
  };

  render = () => null;
}

const mapStateToProps = state => ({
  userData: state.user,
});

const mapDispatchToProps = dispatch => ({
  setUserData: user => dispatch(setUserDataAction(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginMiddleWare);
