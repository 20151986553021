// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H1 from "../../../styleguide/components/Heading/H1.res.js";
import * as H3 from "../../../styleguide/components/Heading/H3.res.js";
import * as Api from "../../../api/Api.res.js";
import * as User from "../../../models/User.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as React from "react";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Spinner from "../../../styleguide/components/Spinner/Spinner.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../styleguide/components/Pagination/Pagination.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProductGrid from "../product-grid/ProductGrid.res.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as CategoryFilter from "../../../models/CategoryFilter.res.js";
import * as SortingPopover from "../sorting-popover/SortingPopover.res.js";
import * as ProviderAsFilter from "../../../models/ProviderAsFilter.res.js";
import * as ProviderByLetter from "../../../models/ProviderByLetter.res.js";
import * as ColocationProduct from "../../../models/ColocationProduct.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as MarketplaceSeoText from "../../../models/MarketplaceSeoText.res.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.res.js";
import * as MarketplaceFilterData from "../filters/MarketplaceFilterData.res.js";
import * as MarketplaceIndexScss from "./MarketplaceIndex.scss";
import * as MarketplaceIndexMobileFilters from "./MarketplaceIndexMobileFilters.res.js";
import * as MarketplaceIndexDesktopFilters from "./MarketplaceIndexDesktopFilters.res.js";

var css = MarketplaceIndexScss;

function paramsConstructor(state) {
  return "?page=" + (String(state.currentPage) + ("&" + (MarketplaceFilterData.Input.toQueryString(state.filters) + ("&" + SortingPopover.SortBy.toQueryString(state.sortBy)))));
}

var Helpers = {
  paramsConstructor: paramsConstructor
};

function MarketplaceIndex$MarketplaceIndex(props) {
  var userLoginStatus = props.userLoginStatus;
  var seoTexts = props.seoTexts;
  var searchParams = props.searchParams;
  var totalLocations = props.totalLocations;
  var providersGrouped = props.providersGrouped;
  var totalProviders = props.totalProviders;
  var providers = props.providers;
  var categoryFilters = props.categoryFilters;
  var totalPages = props.totalPages;
  var totalProducts = props.totalProducts;
  var currentPage = props.currentPage;
  var products = props.products;
  var tablet = props.tablet;
  var mobile = props.mobile;
  var productsContainer = React.useRef(null);
  var fetchProducts = ReactDebounce.useDebounced(500, (function (param) {
          RescriptReactRouter.push(paramsConstructor(param.state));
          param.dispatch("FetchProducts");
        }));
  var initialState = React.useMemo((function () {
          return {
                  products: products,
                  currentPage: currentPage,
                  totalProducts: totalProducts,
                  totalPages: totalPages,
                  sortBy: "TitleAToZ",
                  fetching: false,
                  filters: searchParams,
                  seoTexts: seoTexts
                };
        }), []);
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            return {
                    TAG: "UpdateWithSideEffects",
                    _0: {
                      products: state.products,
                      currentPage: state.currentPage,
                      totalProducts: state.totalProducts,
                      totalPages: state.totalPages,
                      sortBy: state.sortBy,
                      fetching: true,
                      filters: state.filters,
                      seoTexts: state.seoTexts
                    },
                    _1: (function (param) {
                        var dispatch = param.dispatch;
                        var state = param.state;
                        $$Promise.wait(Api.fetchProducts(state.currentPage, state.sortBy, state.filters, undefined), (function (x) {
                                if (x.TAG === "Ok") {
                                  return dispatch({
                                              TAG: "UpdateProducts",
                                              _0: x._0
                                            });
                                } else {
                                  return SentryLogger.error1({
                                              rootModule: "MarketplaceIndex",
                                              subModulePath: {
                                                hd: "MarketplaceIndex",
                                                tl: /* [] */0
                                              },
                                              value: "make",
                                              fullPath: "MarketplaceIndex.MarketplaceIndex.make"
                                            }, "MarketplaceIndex::FetchProducts::Error", [
                                              "Error",
                                              x._0
                                            ]);
                                }
                              }));
                      })
                  };
          }
          switch (action.TAG) {
            case "Paginate" :
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          products: state.products,
                          currentPage: action._0,
                          totalProducts: state.totalProducts,
                          totalPages: state.totalPages,
                          sortBy: state.sortBy,
                          fetching: state.fetching,
                          filters: state.filters,
                          seoTexts: state.seoTexts
                        },
                        _1: (function (param) {
                            RescriptReactRouter.push(paramsConstructor(param.state));
                            Belt_Option.map(Caml_option.nullable_to_opt(productsContainer.current), (function (prim) {
                                    prim.scrollIntoView();
                                  }));
                            param.dispatch("FetchProducts");
                          })
                      };
            case "Sort" :
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          products: state.products,
                          currentPage: state.currentPage,
                          totalProducts: state.totalProducts,
                          totalPages: state.totalPages,
                          sortBy: action._0,
                          fetching: state.fetching,
                          filters: state.filters,
                          seoTexts: state.seoTexts
                        },
                        _1: (function (param) {
                            RescriptReactRouter.push(paramsConstructor(param.state));
                            param.dispatch("FetchProducts");
                          })
                      };
            case "UpdateProducts" :
                var res = action._0;
                return {
                        TAG: "Update",
                        _0: {
                          products: res.products,
                          currentPage: res.currentPage,
                          totalProducts: res.totalProducts,
                          totalPages: res.totalPages,
                          sortBy: state.sortBy,
                          fetching: false,
                          filters: state.filters,
                          seoTexts: res.seoTexts
                        }
                      };
            case "UpdateFilter" :
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          products: state.products,
                          currentPage: 1,
                          totalProducts: state.totalProducts,
                          totalPages: state.totalPages,
                          sortBy: state.sortBy,
                          fetching: state.fetching,
                          filters: action._0,
                          seoTexts: state.seoTexts
                        },
                        _1: fetchProducts
                      };
            case "ImmediatlyUpdateFilter" :
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          products: state.products,
                          currentPage: 1,
                          totalProducts: state.totalProducts,
                          totalPages: state.totalPages,
                          sortBy: state.sortBy,
                          fetching: state.fetching,
                          filters: action._0,
                          seoTexts: state.seoTexts
                        },
                        _1: (function (param) {
                            param.dispatch("FetchProducts");
                          })
                      };
            
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  return JsxRuntime.jsxs("div", {
              children: [
                !mobile && !tablet ? JsxRuntime.jsx(MarketplaceIndexDesktopFilters.make, {
                        filters: state.filters,
                        providers: providers,
                        providersGrouped: providersGrouped,
                        totalProviders: totalProviders,
                        categoryFilters: categoryFilters,
                        totalLocations: totalLocations,
                        update: (function (filters) {
                            dispatch({
                                  TAG: "UpdateFilter",
                                  _0: filters
                                });
                          })
                      }) : JsxRuntime.jsx(MarketplaceIndexMobileFilters.make, {
                        filters: state.filters,
                        providers: providers,
                        providersGrouped: providersGrouped,
                        totalProviders: totalProviders,
                        categoryFilters: categoryFilters,
                        totalLocations: totalLocations,
                        apply: (function (filters) {
                            dispatch({
                                  TAG: "ImmediatlyUpdateFilter",
                                  _0: filters
                                });
                          })
                      }),
                JsxRuntime.jsxs("div", {
                      children: [
                        !mobile && !tablet ? null : JsxRuntime.jsx("div", {
                                className: css.responsiveFix
                              }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: String(state.totalProducts) + " Colocation Products",
                                      className: css.productsTotal
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(SortingPopover.make, {
                                            sortBy: state.sortBy,
                                            userLoginStatus: userLoginStatus,
                                            onClick: (function (sortBy) {
                                                dispatch({
                                                      TAG: "Sort",
                                                      _0: sortBy
                                                    });
                                              })
                                          }),
                                      className: css.sorting
                                    })
                              ],
                              className: Cx.cx([
                                    css.marketplaceLayoutRow,
                                    css.sortingContainer
                                  ])
                            }),
                        state.fetching ? JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx(Spinner.make, {
                                      size: "XL",
                                      color: "Teal"
                                    }),
                                className: css.spinnerContainer
                              }) : JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                children: [
                                  JsxRuntime.jsx(ProductGrid.make, {
                                        products: state.products,
                                        userLoginStatus: userLoginStatus,
                                        setUserData: props.setUserData,
                                        setCartQty: props.setCartQty,
                                        toggleCart: props.toggleCart,
                                        mobile: mobile
                                      }),
                                  JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsx("div", {
                                                children: state.totalPages > 1 ? JsxRuntime.jsx(Pagination.make, {
                                                        currentPage: state.currentPage,
                                                        totalPages: state.totalPages,
                                                        onPageClick: (function (pageNum) {
                                                            dispatch({
                                                                  TAG: "Paginate",
                                                                  _0: pageNum
                                                                });
                                                          })
                                                      }) : null,
                                                className: css.paginationBar
                                              }),
                                          JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsx("span", {
                                                      children: null
                                                    }),
                                                className: css.paginationInfo
                                              })
                                        ],
                                        className: Cx.cx([
                                              css.marketplaceLayoutRow,
                                              css.paginationContainer
                                            ])
                                      })
                                ]
                              }),
                        JsxRuntime.jsx("div", {
                              className: css.divider
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("div", {
                                    children: Belt_Array.mapWithIndex(state.seoTexts, (function (idx, seoText) {
                                            return JsxRuntime.jsxs(React.Fragment, {
                                                        children: [
                                                          seoText.principal ? JsxRuntime.jsx(H1.make, {
                                                                  children: seoText.title
                                                                }) : JsxRuntime.jsx(H3.make, {
                                                                  children: seoText.title
                                                                }),
                                                          JsxRuntime.jsx("p", {
                                                                dangerouslySetInnerHTML: {
                                                                  __html: seoText.description
                                                                }
                                                              })
                                                        ]
                                                      }, String(idx));
                                          })),
                                    className: css.seoText
                                  }),
                              className: css.seoTextContainer
                            })
                      ],
                      ref: Caml_option.some(productsContainer),
                      className: css.productsContainer
                    })
              ],
              className: css.marketplaceLayout
            });
}

var MarketplaceIndex = {
  css: css,
  Helpers: Helpers,
  make: MarketplaceIndex$MarketplaceIndex
};

function MarketplaceIndex$default(props) {
  var reduxfunction = props.reduxfunction;
  var context = props.context;
  var props$1 = props.props;
  return JsxRuntime.jsx(MarketplaceIndex$MarketplaceIndex, {
              mobile: context.mobile,
              tablet: context.tablet,
              products: Belt_Array.map(props$1.products, ColocationProduct.fromJs),
              currentPage: props$1.currentPage,
              totalProducts: props$1.totalProducts,
              totalPages: props$1.totalPages,
              categoryFilters: Belt_Array.map(props$1.filters, CategoryFilter.fromJs),
              providers: Belt_Array.map(props$1.providers, ProviderAsFilter.fromJs),
              totalProviders: props$1.totalProviders,
              providersGrouped: Belt_Array.map(props$1.providersGrouped, ProviderByLetter.fromJs),
              totalLocations: props$1.totalLocations,
              searchParams: MarketplaceFilterData.Input.fromJs(props$1.searchParams),
              seoTexts: Belt_Array.map(props$1.seoTexts, MarketplaceSeoText.fromJs),
              userLoginStatus: User.LoginStatus.fromBool(props.reduxProps.isUserLoggedIn),
              setUserData: reduxfunction.setUserData,
              setCartQty: reduxfunction.setCartQty,
              toggleCart: reduxfunction.toggleCart
            });
}

var $$default = MarketplaceIndex$default;

export {
  MarketplaceIndex ,
  $$default as default,
}
/* css Not a pure module */
