// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Checkbox from "../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as CityStateCountry from "../../../models/CityStateCountry.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as AutocompleteCitiesField from "../../../styleguide/forms/AutocompleteCitiesField/AutocompleteCitiesField.res.js";
import * as MarketplaceFilterScss from "./MarketplaceFilter.scss";

var css = MarketplaceFilterScss;

function MarketplaceLocationFilter(props) {
  var update = props.update;
  var filters = props.filters;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: "Locations",
                      className: css.filterTitle
                    }),
                JsxRuntime.jsx(AutocompleteCitiesField.make, {
                      id: "search-location",
                      placeholder: "Search from " + (String(props.totalLocations) + " locations"),
                      onSelected: (function ($$location) {
                          if ($$location === undefined) {
                            return ;
                          }
                          var locations = filters.locations;
                          update({
                                locations: Belt_Array.concat(locations, [$$location]),
                                providerIds: filters.providerIds,
                                totalPowerKw: filters.totalPowerKw,
                                powerCircuits: filters.powerCircuits,
                                subcategories: filters.subcategories,
                                category: filters.category
                              });
                        }),
                      className: css.searchBox
                    }),
                Belt_Array.mapWithIndex(filters.locations, (function (idx, $$location) {
                        var optionId = "marketplace-filter-location-" + String(idx);
                        return JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                    id: optionId,
                                    size: "MD",
                                    checked: true,
                                    className: css.filterOption,
                                    onChange: (function (param) {
                                        var locations = Belt_Array.keep(filters.locations, (function (value) {
                                                if (value.city !== $$location.city || value.state !== $$location.state) {
                                                  return true;
                                                } else {
                                                  return value.country !== $$location.country;
                                                }
                                              }));
                                        update({
                                              locations: locations,
                                              providerIds: filters.providerIds,
                                              totalPowerKw: filters.totalPowerKw,
                                              powerCircuits: filters.powerCircuits,
                                              subcategories: filters.subcategories,
                                              category: filters.category
                                            });
                                      }),
                                    children: CityStateCountry.toLabel($$location)
                                  }, optionId);
                      }))
              ],
              className: css.filterContainer
            });
}

var make = MarketplaceLocationFilter;

export {
  css ,
  make ,
}
/* css Not a pure module */
