// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Control from "../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../styleguide/components/Popover/Popover.res.js";
import * as Checkbox from "../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as PervasivesU from "rescript/lib/es6/pervasivesU.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as MarketplaceFilterScss from "./MarketplaceFilter.scss";

var css = MarketplaceFilterScss;

function categoryValues(filters, category) {
  switch (category) {
    case "Power" :
        return filters.powerCircuits;
    case "Space" :
        return filters.subcategories;
    default:
      return PervasivesU.failwith("Unknown category filter");
  }
}

function newCategoryValues(filters, values, category) {
  switch (category) {
    case "Power" :
        return {
                locations: filters.locations,
                providerIds: filters.providerIds,
                totalPowerKw: filters.totalPowerKw,
                powerCircuits: values,
                subcategories: filters.subcategories,
                category: filters.category
              };
    case "Space" :
        return {
                locations: filters.locations,
                providerIds: filters.providerIds,
                totalPowerKw: filters.totalPowerKw,
                powerCircuits: filters.powerCircuits,
                subcategories: values,
                category: filters.category
              };
    default:
      return PervasivesU.failwith("Unknown category filter");
  }
}

function categoryNamePlural(category) {
  switch (category) {
    case "Power" :
        return "powers";
    case "Space" :
        return "spaces";
    default:
      return PervasivesU.failwith("Unknown category filter");
  }
}

function limitOptions(options) {
  return options.length > 10;
}

function visibleOptions(options) {
  if (options.length > 10) {
    return Belt_Array.slice(options, 0, 5);
  } else {
    return options;
  }
}

function renderOption(option, filters, update, title, idx) {
  var optionId = "marketplace-filter-" + (title + String(idx));
  return JsxRuntime.jsx(Checkbox.WithLabel.make, {
              id: optionId,
              size: "MD",
              checked: (function (__x) {
                    return Js_array.findIndex((function (value) {
                                  return value === option;
                                }), __x);
                  })(categoryValues(filters, title)) > -1,
              className: css.filterOption,
              onChange: (function ($$event) {
                  var checked = $$event.target.checked;
                  var selectedValues = categoryValues(filters, title);
                  if (checked) {
                    var newCategoryValues$1 = newCategoryValues(filters, Belt_Array.concat(selectedValues, [option]), title);
                    return update(newCategoryValues$1);
                  }
                  var newCategoryValues$2 = newCategoryValues(filters, Belt_Array.keep(selectedValues, (function (value) {
                              return value !== option;
                            })), title);
                  update(newCategoryValues$2);
                }),
              children: option
            }, optionId);
}

var Helpers = {
  categoryValues: categoryValues,
  newCategoryValues: newCategoryValues,
  categoryNamePlural: categoryNamePlural,
  limitOptions: limitOptions,
  visibleOptions: visibleOptions,
  renderOption: renderOption
};

function MarketplaceCategoryFilter(props) {
  var update = props.update;
  var options = props.options;
  var title = props.title;
  var filters = props.filters;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: title,
                      className: css.filterTitle
                    }),
                Belt_Array.mapWithIndex(visibleOptions(options), (function (idx, option) {
                        return renderOption(option, filters, update, title, idx);
                      })),
                options.length > 10 ? JsxRuntime.jsxs(Popover.make, {
                        children: [
                          JsxRuntime.jsx(Popover.Trigger.make, {
                                className: css.popoverTrigger,
                                children: JsxRuntime.jsx(Control.AsLink.make, {
                                      inline: true,
                                      className: css.showAllLink,
                                      onClick: (function (param) {
                                          
                                        }),
                                      children: "All " + (String(options.length) + (" " + categoryNamePlural(title)))
                                    })
                              }),
                          JsxRuntime.jsx(Popover.Body.make, {
                                position: "OnRight",
                                className: css.popover,
                                children: JsxRuntime.jsx("div", {
                                      children: Belt_Array.mapWithIndex(options, (function (idx, option) {
                                              return renderOption(option, filters, update, title, idx);
                                            })),
                                      className: css.optionsPopoverContainer
                                    })
                              })
                        ]
                      }) : null
              ],
              className: css.filterContainer
            });
}

var maxVisibleItems = 10;

var maxItems = 5;

var make = MarketplaceCategoryFilter;

export {
  css ,
  maxVisibleItems ,
  maxItems ,
  Helpers ,
  make ,
}
/* css Not a pure module */
