// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as MarketplaceIndexScss from "./MarketplaceIndex.scss";
import * as MarketplaceIndexFilters from "./MarketplaceIndexFilters.res.js";

var css = MarketplaceIndexScss;

function MarketplaceIndexDesktopFilters(props) {
  return JsxRuntime.jsx(MarketplaceIndexFilters.make, {
              filters: props.filters,
              providers: props.providers,
              providersGrouped: props.providersGrouped,
              totalProviders: props.totalProviders,
              categoryFilters: props.categoryFilters,
              totalLocations: props.totalLocations,
              className: css.filters,
              update: props.update
            });
}

var make = MarketplaceIndexDesktopFilters;

export {
  css ,
  make ,
}
/* css Not a pure module */
