// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ProviderAsFilter from "./ProviderAsFilter.res.js";

function fromJs(js) {
  return {
          letter: js.letter,
          providers: Belt_Array.map(js.providers, ProviderAsFilter.fromJs)
        };
}

export {
  fromJs ,
}
/* ProviderAsFilter Not a pure module */
