// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ProductTile from "../product-tile/ProductTile.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProductGridScss from "./ProductGrid.scss";

var css = ProductGridScss;

function ProductGrid(props) {
  var __mobile = props.mobile;
  var __toggleCart = props.toggleCart;
  var __setCartQty = props.setCartQty;
  var setUserData = props.setUserData;
  var userLoginStatus = props.userLoginStatus;
  var setCartQty = __setCartQty !== undefined ? __setCartQty : (function (prim) {
        
      });
  var toggleCart = __toggleCart !== undefined ? __toggleCart : (function (prim) {
        
      });
  var mobile = __mobile !== undefined ? __mobile : false;
  return JsxRuntime.jsx("div", {
              children: Belt_Array.map(props.products, (function (product) {
                      return JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsx(ProductTile.make, {
                                        product: product,
                                        userLoginStatus: userLoginStatus,
                                        setUserData: setUserData,
                                        setCartQty: setCartQty,
                                        toggleCart: toggleCart,
                                        mobile: mobile
                                      }),
                                  className: css.column
                                }, ID.toString(product.id));
                    })),
              className: css.row
            });
}

var make = ProductGrid;

export {
  css ,
  make ,
}
/* css Not a pure module */
