// Generated by ReScript, PLEASE EDIT WITH CARE


function fromJs(js) {
  return {
          title: js.title,
          values: js.values
        };
}

export {
  fromJs ,
}
/* No side effect */
