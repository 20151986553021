import { CART_TOGGLE, SET_CART_QTY } from 'Utils/actionTypes';

export const toggleCart = () => ({
  type: CART_TOGGLE,
  payload: {},
});

export const setCartQty = totalCartQuantity => ({
  type: SET_CART_QTY,
  payload: { totalCartQuantity },
});
