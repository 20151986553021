// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as MarketplaceCategoryFilter from "../filters/MarketplaceCategoryFilter.res.js";
import * as MarketplaceLocationFilter from "../filters/MarketplaceLocationFilter.res.js";
import * as MarketplaceProviderFilter from "../filters/MarketplaceProviderFilter.res.js";

function MarketplaceIndexFilters(props) {
  var update = props.update;
  var filters = props.filters;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(MarketplaceProviderFilter.make, {
                      filters: filters,
                      update: update,
                      providers: props.providers,
                      allProviders: props.providersGrouped,
                      totalProviders: props.totalProviders
                    }),
                JsxRuntime.jsx(MarketplaceLocationFilter.make, {
                      filters: filters,
                      update: update,
                      totalLocations: props.totalLocations
                    }),
                Belt_Array.mapWithIndex(props.categoryFilters, (function (idx, filter) {
                        return JsxRuntime.jsx(MarketplaceCategoryFilter.make, {
                                    filters: filters,
                                    title: filter.title,
                                    options: filter.values,
                                    update: update
                                  }, String(idx));
                      }))
              ],
              className: props.className
            });
}

var make = MarketplaceIndexFilters;

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
